<!--
	This is the Orders List page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>

    <!-- Orders List header -->
    <!-- / Orders List header -->

    <!-- Orders List card -->
    <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}" v-if="pageFlag === 'list'">

      <!-- Table search -->
      <div class="mx-25">
        <a-row type="flex" :gutter="24">
          <a-col :span="12" class="text-left">
            <a-input-search placeholder="请输入查询信息" style="max-width: 200px;" v-model="query" @search="onSearch" size="small" />
          </a-col>
          <a-col :span="12" class="text-right">
            <a-button type="primary" size="small" @click="visible = true">{{ $t('create') }}</a-button>
          </a-col>
        </a-row>
      </div>
      <!-- / Table search -->

      <!-- Orders table -->
      <a-table class="mt-20"
               :columns="columns"
               :data-source="data"
               rowKey="code"
               :loading="loading"
               :pagination="{pageSize: pageSize,current: pageNum,total:total}"
               :scroll="{ x: true, y: true }"
               @change="handleTableChange"
      >

        <template slot="name" slot-scope="row">
          <a href="javascript:void(0)" @click="goDetail(row)">{{ row.name }}</a>
        </template>


        <!--        <template slot="status" slot-scope="status">-->
        <!--          <a-button v-if="status == 'Paid'" shape="circle" size="small" class="btn-status border-success mr-5">-->
        <!--            <a-icon class="m-0 text-success" type="check" style="font-size: 10px;" />-->
        <!--          </a-button>-->
        <!--          <a-button v-else-if="status == 'Refunded'" shape="circle" size="small" class="btn-status border-muted mr-5">-->
        <!--            <a-icon class="m-0 text-muted" type="undo" style="font-size: 10px;" />-->
        <!--          </a-button>-->
        <!--          <a-button v-else-if="status == 'Canceled'" shape="circle" size="small" class="btn-status border-danger mr-5">-->
        <!--            <a-icon class="m-0 text-danger" type="close" style="font-size: 10px;" />-->
        <!--          </a-button>-->
        <!--          <span style="vertical-align: middle;">{{ status }}</span>-->
        <!--        </template>-->

        <!--        <template slot="customer" slot-scope="customer">-->
        <!--          <div class="table-avatar-info">-->
        <!--            <a-avatar v-if="customer.avatar" shape="circle" :size="24" :src="customer.avatar" />-->
        <!--            <a-avatar v-else shape="circle" :size="24">{{ customer.name.slice(0, 1) }}</a-avatar>-->
        <!--            <div class="avatar-info">-->
        <!--              <p class="mb-0 text-dark">{{ customer.name }}</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </template>-->


        <template slot="status" slot-scope="status">
          <span v-if="status === 1" style="vertical-align: middle;font-size: 12px;color: #87d068">{{ $t('enable') }}</span>
          <span v-else style="vertical-align: middle;font-size: 12px;color: #f50">{{ $t('invalid') }}</span>
        </template>
        <template slot="operation" slot-scope="row">
          <a-icon type="edit" style="cursor: pointer;margin-right: 10px" @click="edit(row)"/>
          <a-popconfirm :title="$t('delConfirmMsg')" :ok-text="$t('yes')" :cancel-text="$t('no')" @confirm="del(row.id)">
            <a-icon type="delete" style="cursor: pointer"/>
          </a-popconfirm>
        </template>

      </a-table>
      <!-- / Orders table -->

    </a-card>
    <a-drawer
        :title="drawerTitle"
        placement="right"
        :closable="true"
        :visible="visible"
        width="40%"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
    >
      <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0 }" :loading="drawerLoading">
        <a-form-item class="mb-10" :label="$t('questionnaire.title') + ' [中文]'" :colon="false" required>
          <a-input v-model="form.nameZh" size="small"/>
        </a-form-item>
        <a-form-item class="mb-10" :label="$t('questionnaire.title') + ' [EN]'" :colon="false" required>
          <a-input v-model="form.nameEn" size="small"/>
        </a-form-item>
        <a-form-item class="mb-10" :label="$t('questionnaire.status')" :colon="false" >
          <a-select
              show-search
              allowClear
              option-filter-prop="children"
              style="width: 100%;margin-right: 8px"
              size="small"
              v-model="form.status"
              :filter-option="filterOption"
          >
            <a-select-option :value="1" >
              {{ $t('enable') }}
            </a-select-option>
            <a-select-option :value="0" >
              {{ $t('invalid') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="text-align: right">
          <a-input v-model="form.id" size="small" type="hidden"/>
          <a-button type="primary" html-type="submit" @click="submit"  size="small">
            {{ $t('submit') }}
          </a-button>
        </a-form-item>
      </a-card>
    </a-drawer>
    <!-- / Orders List card -->
    <detail v-if="pageFlag === 'detail'" :id="detailId" :title="detailTitle"/>

  </div>

</template>

<script>

import { getToken } from "../../utils/auth"
import Detail from "./Detail";

export default {
  components: { Detail },
  data() {
    return {
      pageFlag: 'list',
      postFlag: true,
      loading: false,
      uploadLoading: false,
      drawerLoading: false,
      visible: false,
      drawerTitle: this.$t('create'),
      imageUrl: '',
      // Table columns
      columns: [
        {
          title: this.$i18n.t('questionnaire.title'),
          scopedSlots: { customRender: 'name' },
          width: 250
        },
        {
          title: this.$i18n.t('role.status'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: this.$i18n.t('lastModifiedDate'),
          dataIndex: 'lastModifiedDate',
          width: 180
        },
        {
          title: this.$i18n.t('operation'),
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: 120
        },
      ],

      // Table rows
      data: [],

      // Table page size
      pageSize: 10,
      pageNum: 1,
      total: 0,

      // Table search query
      query: '',

      // Table's selected rows
      selectedRowKeys: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      detailId: null,
      detailTitle: '',
      form: {
        id: null,
        name: null,
        status: 1,
        nameZh: null,
        nameEn: null,
        lastModifiedDate: null
      },
      uploadHeaders: {
        Authorization: 'Bearer ' + getToken()
      },
      ossBaseUrl: process.env.VUE_APP_OSS_BASE_URL,
      baseUrl: process.env.VUE_APP_BASE_API
    }
  },
  computed: {

    // CSV data array
    csvData() {
      return this.data.map(item => ({
        "Id": "%23" + item.key,
        "Date": item.date,
        "Status": item.status,
        "Customer": item.customer.name,
        "Product": item.product,
        "Revenue": "$" + item.revenue,
      }));
    },
    goList() {
      this.pageFlag = 'list'
    }

  },
  mounted() {
    this.getData()
  },
  methods: {
    afterVisibleChange() {},
    onSubmit() {},
    onClose() {
      this.visible = false
      this.resetForm()
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // Event listener for input change on table search field.
    onSearch() {
      this.getData()
    },

    // Event listener for table row selection change.
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // Export table in CSV format.
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join("|"),
        ...arrData.map(item => Object.values(item).join("|"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "muse-dashboard-csv.csv");
      link.click();
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        if (info.file.response.errorCode === '0') {
          this.imageUrl = info.file.response.data
        } else {
          this.$message.error(info.file.response.message)
        }
        this.uploadLoading = false;
        // this.getBase64(info.file.originFileObj, imageUrl => {
        //   this.imageUrl = imageUrl;
        //   this.drawerLoading = false;
        // });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    getData() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const searchForm = {
        name: this.query
      }
      this.$store.dispatch('QUESTIONNAIRE_LIST', {params: params, data: searchForm}).then(res => {
        if (res.errorCode === '0') {
          this.data = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    submit() {
      this.drawerLoading = true
      this.form.logo = this.imageUrl
      if (this.postFlag) {
        this.postFlag = false
        this.$store.dispatch("SAVE_QUESTIONNAIRE", this.form).then(res => {
          this.drawerLoading = false
          this.postFlag = true
          if (res.errorCode === '0') {
            this.visible = false
            this.resetForm()
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.drawerLoading = false
          this.postFlag = true
          this.$message.error(err)
        })

      }
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        status: 1,
        nameZh: null,
        nameEn: null,
        lastModifiedDate: null
      }
      this.imageUrl = null
      this.drawerTitle = this.$t('create')
    },
    edit(row) {
      this.drawerTitle = this.$t('edit')
      this.form = row
      this.imageUrl = row.logo
      this.visible = true
    },
    handleTableChange(pagination) {
      this.pageNum = pagination.current
      this.total = pagination.total
      this.getData()
    },
    del(id) {
      const param = {
        questionnaireId: id
      }
      if (this.postFlag) {
        this.postFlag = false
        this.loading = true
        this.$store.dispatch('DELETE_QUESTIONNAIRE', param).then(res => {
          this.postFlag = true
          if (res.errorCode === '0') {
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        }).catch(err => {
          this.postFlag = true
          this.loading = false
          this.$message.error(err)
        })
      }
    },
    goDetail(row) {
      this.pageFlag = 'detail'
      this.detailId = row.id
      this.detailTitle = row.name
    }
  },
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
  display: flex;
  align-items: center;
}
.table-avatar-info .ant-avatar {
  margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
  font-size: 12px;
}
.btn-status::v-deep .anticon {
  line-height: 0;
}
.ant-select::v-deep .ant-select-selection__placeholder {
  font-weight: normal;
}
.ant-input-search::v-deep input::placeholder {
  font-weight: normal;
}
</style>