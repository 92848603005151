<!--
	This is the Orders List page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>

    <!-- Orders List header -->
    <!-- / Orders List header -->

    <!-- Orders List card -->
    <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">

      <!-- Table search -->
      <div class="mx-25">
        <a-row type="flex" :gutter="24">
          <a-col :span="12" class="text-left">
            <a-button type="link" style="display: inline-block;" size="small" @click="goBack()"><a-icon type="left" /></a-button>
            <span style="display: inline-block;margin-right: 10px;font-size: 16px;font-weight: bold">{{ title }}</span>
            <!--            <a-input-search placeholder="请输入查询信息" style="max-width: 200px;" v-model="query" @search="onSearch" size="small" />-->
          </a-col>
          <a-col :span="12" class="text-right">
            <a-button type="primary" size="small" @click="visible = true">{{ $t('questionnaire.add') }}</a-button>
            <a-button type="primary" size="small" @click="visible = true" style="margin-left: 5px">{{ $t('preview') }}</a-button>
          </a-col>
        </a-row>
      </div>
      <!-- / Table search -->

      <!-- Orders table -->
      <a-table class="mt-20"
               :columns="columns"
               :data-source="data"
               rowKey="code"
               :loading="loading"
               :pagination="{pageSize: pageSize,current: pageNum,total:total}"
               :scroll="{ x: true, y: true }"
               @change="handleTableChange"
      >


        <template slot="type" slot-scope="type">
          <span v-if="type == 1">{{ $t('text') }}</span>
          <span  v-if="type == 2">{{ $t('radio') }}</span>
          <span  v-if="type == 3">{{ $t('checkbox') }}</span>
        </template>

        <template slot="status" slot-scope="status">
          <span v-if="status === 1" style="vertical-align: middle;font-size: 12px;color: #87d068">{{ $t('enable') }}</span>
          <span v-else style="vertical-align: middle;font-size: 12px;color: #f50">{{ $t('invalid') }}</span>
        </template>

        <template slot="isRequired" slot-scope="isRequired">
          <span v-if="isRequired == 1" style="vertical-align: middle;font-size: 12px;color: #f50">{{ $t('yes') }}</span>
          <span v-else style="vertical-align: middle;font-size: 12px;color: #87d068">{{ $t('no') }}</span>
        </template>

        <template slot="operation" slot-scope="row">
          <a-icon type="edit" style="cursor: pointer;margin-right: 10px" @click="edit(row)"/>
          <a-popconfirm :title="$t('delConfirmMsg')" :ok-text="$t('yes')" :cancel-text="$t('no')" @confirm="del(row.id)">
            <a-icon type="delete" style="cursor: pointer"/>
          </a-popconfirm>
        </template>

      </a-table>
      <!-- / Orders table -->

    </a-card>
    <a-drawer
        :title="drawerTitle"
        placement="right"
        :closable="true"
        :visible="visible"
        width="40%"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
    >
      <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0 }" :loading="drawerLoading">
        <a-form-item class="mb-10" :label="$t('questionnaire.topic') + ' [中文]'" :colon="false" required>
          <a-input v-model="form.nameZh" size="small"/>
        </a-form-item>
        <a-form-item class="mb-10" :label="$t('questionnaire.topic') + ' [EN]'" :colon="false" required>
          <a-input v-model="form.nameEn" size="small"/>
        </a-form-item>
        <a-form-item :label="$t('questionnaire.type')">
          <a-radio-group v-model="form.type">
            <a-radio :value="1">{{ $t('text') }}</a-radio>
            <a-radio :value="2">{{ $t('radio') }}</a-radio>
            <a-radio :value="3">{{ $t('checkbox') }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item class="mb-10" :label="$t('questionnaire.option')" :colon="false" required v-if="form.type != '1'">
          <a-input-group compact v-for="(item, index) in options" :key="index" :style="index !== 0 ? 'margin-top: 5px' : ''">
            <a-input style="width: calc(100% - 50px)" v-model:value="item.name"/>
            <a-tooltip :title="$t('questionnaire.delOption')" v-if="index < options.length - 1">
              <a-button type="primary" @click="optionAction('del', index)">
                <a-icon type="minus" theme="outlined" />
              </a-button>
            </a-tooltip>
            <a-tooltip :title="$t('questionnaire.addOption')" v-else>
              <a-button type="primary" @click="optionAction('add')">
                <a-icon type="plus" theme="outlined" />
              </a-button>
            </a-tooltip>
          </a-input-group>
        </a-form-item>
        <a-form-item class="mb-10" :label="$t('questionnaire.status')" :colon="false" >
          <a-select
              show-search
              allowClear
              option-filter-prop="children"
              style="width: 100%;margin-right: 8px"
              size="small"
              v-model="form.status"
              :filter-option="filterOption"
          >
            <a-select-option :value="1" >
              {{ $t('enable') }}
            </a-select-option>
            <a-select-option :value="0" >
              {{ $t('invalid') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="mb-10" :label="$t('questionnaire.isRequired')" :colon="false" >
          <a-select
              show-search
              allowClear
              option-filter-prop="children"
              style="width: 100%;margin-right: 8px"
              size="small"
              v-model="form.isRequired"
              :filter-option="filterOption"
          >
            <a-select-option :value="1" >
              {{ $t('yes') }}
            </a-select-option>
            <a-select-option :value="0" >
              {{ $t('no') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="text-align: right">
          <a-input v-model="form.id" size="small" type="hidden"/>
          <a-button type="primary" html-type="submit" @click="submit"  size="small">
            {{ $t('submit') }}
          </a-button>
        </a-form-item>
      </a-card>
    </a-drawer>
    <!-- / Orders List card -->

  </div>

</template>

<script>

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      postFlag: true,
      loading: false,
      uploadLoading: false,
      drawerLoading: false,
      visible: false,
      drawerTitle: this.$t('add'),
      imageUrl: '',
      // Table columns
      columns: [
        {
          title: this.$i18n.t('questionnaire.topic'),
          dataIndex: 'name'
        },
        {
          title: this.$i18n.t('questionnaire.type'),
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 100
        },
        {
          title: this.$i18n.t('questionnaire.isRequired'),
          dataIndex: 'isRequired',
          scopedSlots: { customRender: 'isRequired' },
          width: 150
        },
        {
          title: this.$i18n.t('questionnaire.status'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: this.$i18n.t('lastModifiedDate'),
          dataIndex: 'lastModifiedDate',
          width: 180
        },
        {
          title: this.$i18n.t('operation'),
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: 120
        },
      ],

      // Table rows
      data: [],

      // Table page size
      pageSize: 10,
      pageNum: 1,
      total: 0,

      // Table search query
      query: '',

      // Table's selected rows
      selectedRowKeys: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: null,
        questionnaireId: this.id,
        isRequired: 0,
        nameZh: null,
        nameEn: null,
        type: 1,
        status: 1,
        options: [{name:''}]
      },
      options: [{name:''}]
    }
  },
  computed: {

    // CSV data array
    csvData() {
      return this.data.map(item => ({
        "Id": "%23" + item.key,
        "Date": item.date,
        "Status": item.status,
        "Customer": item.customer.name,
        "Product": item.product,
        "Revenue": "$" + item.revenue,
      }));
    }

  },
  mounted() {
    this.getData()
  },
  methods: {
    afterVisibleChange() {},
    onSubmit() {},
    onClose() {
      this.visible = false
      this.resetForm()
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // Event listener for input change on table search field.
    onSearch() {
      this.getData()
    },

    // Event listener for table row selection change.
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // Export table in CSV format.
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join("|"),
        ...arrData.map(item => Object.values(item).join("|"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "muse-dashboard-csv.csv");
      link.click();
    },
    getData() {
      this.$store.dispatch('QUESTIONNAIRE_DETAIL', {questionnaireId: this.id}).then(res => {
        if (res.errorCode === '0') {
          this.data = res.data.questionnaireTopicDTOS
        } else {
          this.$message.error(res.message)
        }
      })
    },
    submit() {
      this.drawerLoading = true
      let options = []
      for(let i = 0; i < this.options.length; i ++) {
        let option = {}
        option.nameEn = this.options[i].name
        option.nameZh = this.options[i].name
        option.status = 1
        option.ordering = i + 1
        options.push(option)
      }
      this.form.questionnaireOptionDTOS = options
      if (this.postFlag) {
        this.postFlag = false
        this.$store.dispatch("SAVE_QUESTIONNAIRE_TOPIC", this.form).then(res => {
          this.drawerLoading = false
          this.postFlag = true
          if (res.errorCode === '0') {
            this.visible = false
            this.resetForm()
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.drawerLoading = false
          this.postFlag = true
          this.$message.error(err)
        })

      }
    },
    resetForm() {
      this.form = {
        id: null,
        questionnaireId: this.id,
        isRequired: 0,
        nameZh: null,
        nameEn: null,
        type: 1,
        status: 1,
        options: [{name:''}]
      }
      this.drawerTitle = this.$t('add')
    },
    edit(row) {
      this.drawerTitle = this.$t('edit')
      this.form = row
      let options = []
      console.log(row)
      if (row.questionnaireOptionDTOS && row.questionnaireOptionDTOS.length > 0) {
        row.questionnaireOptionDTOS.forEach(x => {
          let option = {}
          option.name = x.nameZh
          options.push(option)
        })
      } else {
        options.push({name:''})
      }
      this.options = options
      this.visible = true
    },
    handleTableChange(pagination) {
      this.pageNum = pagination.current
      this.total = pagination.total
      this.getData()
    },
    del(id) {
      const param = {
        topicId: id
      }
      if (this.postFlag) {
        this.postFlag = false
        this.loading = true
        this.$store.dispatch('DELETE_QUESTIONNAIRE_TOPIC', param).then(res => {
          this.postFlag = true
          if (res.errorCode === '0') {
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        }).catch(err => {
          this.postFlag = true
          this.loading = false
          this.$message.error(err)
        })
      }
    },
    goBack() {
      this.$parent.goList()
    },
    optionAction(action, index) {
      if (action === 'add') {
        this.options.push({name:''})
      } else {
        this.options.splice(index, 1)
      }
      console.log(this.options)
    }
  },
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
  display: flex;
  align-items: center;
}
.table-avatar-info .ant-avatar {
  margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
  font-size: 12px;
}
.btn-status::v-deep .anticon {
  line-height: 0;
}
.ant-select::v-deep .ant-select-selection__placeholder {
  font-weight: normal;
}
.ant-input-search::v-deep input::placeholder {
  font-weight: normal;
}
</style>